
.deploy-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;

    .deploy {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10rem;

    }

    .field-control__wrapper {
        max-width: 50rem;
    }

    .console-container {
        padding-bottom: 2rem;
        flex-grow: 1;
        overflow: auto;
    }

    .typewriter-container {
        padding-left: 3rem;
    }

    .typewriter-wrapper {
        display: inline-block;
    }

    .typewriter {
        font-family: monospace;
        white-space: nowrap;
        overflow: hidden;
        width: 0;
    }

    .wrapper {
        display: flex;
        flex-direction: column;
    }

    @keyframes cursor {
        50% {
            border-right: transparent;
        }
    }

    @keyframes typing {
        from {
            width: 0;
        }

        to {
            width: 100%;
        }

    }

}
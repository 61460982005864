.login-screen{
    display:flex;
    flex-direction: column;
    gap: 1rem;
    height:100%;
    align-items: center;
    justify-content:center;
    &-form{
        display:flex;
        flex-direction: column;
        width:25rem;
        gap: .75rem;
        border-radius: var(--primary-border-radius);
        border: 1px solid var(--primary-border);
        padding:1rem;
    }

    &-button{
        display: flex;
        justify-content: right;
        width:100%;
    }
}